<template>
    <el-container>
        <!-- 中间内容 -->
        <el-main>
            <h2 class="depTitle" v-if="departmentId !== 'null'">科室标化工作量</h2>
            <el-table :data="tableData" border center>
                <el-table-column type="index" label="序号" width="100px" align="center">
                </el-table-column>
                <el-table-column prop="year" label="基期年份" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template style="display: flex" slot-scope="scope">
                        <el-button size="mini" type="primary" class="downBut" v-if="cesuanExpClick" :loading="cesuanExpClickTwo" @click="exportYearExcel(scope.row)"><i class="el-icon-download"></i></el-button>
                        <el-button size="mini" type="info" v-else :loading="cesuanExpClickTwo"></el-button>
                        <el-button size="mini" type="primary" @click="WorkloadClickdialogVisible(scope.row)"><i class="el-icon-document-copy"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[100, 200, 300, 400]" :page-size="pageSize" layout=" prev, pager, next" :total="total">
            </el-pagination>
        </el-main>
        <!-- 工作量当量的弹出框 -->
        <el-dialog :title="yeartext" :visible.sync="WorkloaddialogVisible" width="50%" top="1vh" class="tanchu" append-to-body>
            <el-table :data="WorkloadtableData" border center style="width: 95%; margin: 0 auto; position: relative; z-index: 2">
                <el-table-column prop="month" label="月份" align="center" width="100">
                </el-table-column>
                <el-table-column prop="baseTotal" label="标化工作量" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template style="display: flex" slot-scope="scope">
                        <el-button size="mini" type="primary" class="downBut" v-if="cesuanExpTwoClick" :loading="cesuanExpTwoClickTwo" @click="exportMonthExcel(scope.row)"><i class="el-icon-download"></i></el-button>
                        <el-button size="mini" type="info" v-else :loading="cesuanExpTwoClickTwo"></el-button>
                        <el-button size="mini" type="primary" @click="equivalentClickdialogVisible(scope.row)"><i class="el-icon-notebook-2"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
<!--            <el-table border :data="ZongtableData" style="-->
<!--          width: 95%;-->
<!--          margin: 0 auto;-->
<!--          margin-top: -49px;-->
<!--          position: relative;-->
<!--          z-index: 1;-->
<!--        ">-->
<!--                <el-table-column prop="total" label="xx" align="center" width="100">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="one" label="xx" align="center" width="120">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="two" label="xx" align="center" width="100">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="three" label="xx" align="center" width="100">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="four" label="xx" align="center" width="140">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="five" label="xx" align="center" width="130">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="six" label="xx" align="center" width="140">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="seven" label="xx" align="center" width="150">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="eight" label="xx" align="center" width="150">-->
<!--                </el-table-column>-->
<!--                <el-table-column label="xx" align="center"> </el-table-column>-->
<!--            </el-table>-->
        </el-dialog>
        <!-- 2015年1月工作总量弹出框 -->
        <el-dialog :title="yearMonthtext" :visible.sync="equivalentdialogVisible" width="90%" top="1vh" :before-close="equivalentdialogVisibleClose" append-to-body>
            <el-form class="twoForm" :inline="true" :model="getworkldModel">
                <div>
                    <el-form-item>
                        <div>标化工作总量：{{ Scientific }}</div>
                    </el-form-item>

<!--                    <el-form-item label="结构调整策略：">-->
<!--                        <el-select v-model="confivalue" placeholder="请配置结构调整策略" @change="confiChange(confivalue)" clearable>-->
<!--                            <el-option v-for="item in confioptions" :key="item.id" :label="item.name" :value="item.id">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->

                    <el-form-item>
                        <div>
                            <el-input placeholder="请输入项目名称或项目编码" prefix-icon="el-icon-search" class="index-search" clearable v-model="getworkldModel.searchValue" @blur="getworkldDataList" @keyup.enter.native="getworkldDataList" @clear="getworkldDataList">
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="项目类型：" class="FormType">
                        <el-select v-model="workldvalue" placeholder="请选择" clearable @change="typeworkldDataListValueChange(workldvalue)">
                            <el-option v-for="item in workldoptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>


                  <el-form-item label="基础科室：" class="FormType">
                    <el-select v-model="basIdePvalue" placeholder="请选择" clearable @change="basicDepValueChange(basIdePvalue)">
                      <el-option v-for="item in basiDepoptions" :key="item.id" :label="item.departmentName" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>

                    <el-form-item>
                        <el-button v-if="cesuanClick" type="primary" :loading="cesuanClickKing" @click="updateBaseClassByStandWork">重新计算</el-button>
                        <el-button v-else type="info" :loading="cesuanClickKing"></el-button>
                    </el-form-item>

                  <el-form-item>
                    <el-button type="primary" @click="mouexportData" v-if="mouExpClick" :loading="mouExpClickLoadin">导出数据</el-button>
                    <el-button type="info" v-else :loading="mouExpClickLoadin"></el-button>
                  </el-form-item>
                </div>
            </el-form>
            <el-table :data="equivalenttableData" :row-class-name="tableRowClassName" style="width: 95%; margin: 0 auto" border center>
                <el-table-column type="index" :index="indexMethod" align="center" label="序号" width="55"></el-table-column>
              <el-table-column prop="prescribingDepartmentName" label="开单科室" align="center">
                </el-table-column>
              <el-table-column prop="executeDepartmentName" label="执行科室" align="center">
              </el-table-column>
              <el-table-column prop="projectCode" label="项目代码" align="center">
            </el-table-column>
              <el-table-column prop="priceCode" label="国标码" align="center">
            </el-table-column>
              <el-table-column prop="name" label="项目名称" align="center">
            </el-table-column>
              <el-table-column prop="medicalSort" label="医嘱小类" align="center" width="80">
            </el-table-column>
              <el-table-column prop="currentPrice" label="现行收费价格" align="center" width="110">
            </el-table-column>
              <el-table-column prop="projectScore" label="项目系数" align="center" width="80">
              </el-table-column>
              <el-table-column prop="baseClass" label="基准项目比价系数" align="center">
            </el-table-column>
              <el-table-column prop="score" label="医嘱小类系数" align="center" width="110">
            </el-table-column>
              <el-table-column prop="projectNumber" label="数量" align="center">
            </el-table-column>
              <el-table-column prop="standWork" label="标化工作量" align="center">
            </el-table-column>

                <!--                <el-table-column prop="projectCode" label="项目编码" align="center">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="priceCode" label="收费编码" align="center" width="110">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="name" label="项目名称" align="center">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="currentPrice" label="现行收费价格" align="center" width="105">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="baseClass" label="基准项目比价系数" align="center" width="160" :render-header="(h) => renderHeader(h, '基准项目比价系数')">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="total" label="发生量" align="center" width="90" :render-header="(h) => renderHeader(h, '发生量')">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="standWork" label="标化工作量" align="center" width="120" :render-header="(h) => renderHeader(h, '标化工作量')">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="strategicPrice" label="策略价" align="center">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="strategicPriceStandardizedWorkload" label="策略价标化工作量" align="center" width="140">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="strategicPriceCoefficient" label="策略价基准项目比价系数" align="center" width="120">-->
<!--                </el-table-column>-->
            </el-table>
            <el-pagination background @current-change="handleCurrentequivalentChange" :current-page="equivalentpageNum" :page-size="equivalentpageSize" layout="prev, pager, next" :total="equivalenttotal">
            </el-pagination>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    props: ["depart", "hospital", "depName"],
    data() {
        return {
          basIdePvalue:'',
          basiDepoptions:[],
            confivalue: "",
            confioptions: [],
          mouExpClick:true,
          mouExpClickLoadin:false,
            // 导出按钮置灰
            cesuanExpClick: true,
            cesuanExpClickTwo: false,
            cesuanExpTwoClick: true,
            cesuanExpTwoClickTwo: false,
            workldoptions: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "收费项目",
                },
                {
                    value: "0",
                    label: "非收费项目",
                },
            ],
            workldvalue: "",
            // 2015年1月工作总量
            getworkldModel: {
                searchValue: "",
            },
            // 标化工作总量
            Scientific: "",
            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 表格数据
            tableData: [],
            // 工作量当量的表格
            WorkloadtableData: [],
            // 2015年1月工作总量表格
            equivalenttableData: [],
            ZongtableData: [
                {
                    total: "合计",
                    one: "",
                    two: "",
                    three: "",
                },
                {
                    total: "平均",
                    one: "",
                    two: "",
                    three: "",
                },
            ],
            // 工作量当量弹出框
            WorkloaddialogVisible: false,
            // 工作总量的弹出框
            equivalentdialogVisible: false,
            // 2015年1月工作总量分页
            equivalenttotal: 1,
            equivalentpageNum: 1,
            equivalentpageSize: 10,
            departmentName: "",
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            userType: window.sessionStorage.getItem("type"),
            // 科室年份id
            yid: "",
            // 年份
            yearValue: "",
            // 工作当量的标题
            yeartext: "",
            // 2015年1月工作总量的标题
            yearMonthtext: "",
            // 排序数字
            sortNum: 0,
            // 排序方法
            isAsc: "",
            // 排序参数
            orderBy: "",
            // 点击重新计算2秒置灰
            cesuanClick: true,
            cesuanClickKing: false,
        };
    },
    created() {
        // 0 医院用户   1 科室用户
        // if (window.sessionStorage.getItem("type") == "1") {
        //     this.getDepartmentYearList();
        //     this.departmentName =
        //         window.sessionStorage.getItem("departmentName");
        // } else {
        //     this.departmentName = this.depName;
        // }
        if (window.sessionStorage.getItem("departmentId") !== "null") {
            this.getDepartmentYearList();
            this.departmentName =
                window.sessionStorage.getItem("departmentName");
        } else {
            this.departmentName = this.depName;
        }
    },
    methods: {
      // 查询标化工作总量
      async getDepartmentWorkLoad(){
        let data = {
          dId:this.dId,
          yId:this.yId,
          searchValue:this.getworkldModel.searchValue,
          prescribingDepartmentName:this.basIdePvalue,//基础科室
          type: this.workldvalue, //项目类型（0 非收费项目 1 收费项目 ）不传此参数就是全部
        };
        let res = await this.$axios.getDepartmentWorkLoad(data);
        // console.log(res);
        if (res.status == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          this.Scientific=res.data.standWork
        }else{
          this.$message({
            message: res.statusText,
            type: "error",
          });
        }
      },
      // 基础科室接口
      async basiDepChang(){
        let data
        // 医院管理员 101  科室管理员 102
        if(window.sessionStorage.getItem("deptId")=='101'){
          data = {
            departmentId:this.depart
          };
        }else if(window.sessionStorage.getItem("deptId")=='102'){
          data = {
            departmentId:window.sessionStorage.getItem("departmentId")
          };
        }
        let res = await this.$axios.findBasicsDepartmentByDepartmentId(data);
        // console.log(res);
        if (res.status == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          this.basiDepoptions=res.data
        }else{
          this.$message({
            message: res.statusText,
            type: "error",
          });
        }
      },
      // 选择基础科室
      basicDepValueChange(row){
        if(row){
          this.basiDepoptions.map((item)=>{
            if(item.id==row){
              this.basIdePvalue=item.departmentName
            }
          })
        }else{
          this.basIdePvalue=''
        }
        this.getDepartmentPriceRatioStandList()
        this.getDepartmentWorkLoad()
      },
      async mouexportData(){
        this.mouExpClick = false;
        this.mouExpClickLoadin = true;
        let params = {
          dId:this.dId,
          yId:this.yId,
          searchValue:this.getworkldModel.searchValue,
          prescribingDepartmentName:this.basIdePvalue,//基础科室
          type: this.workldvalue, //项目类型（0 非收费项目 1 收费项目 ）不传此参数就是全部
        };
        let res = await this.$axios.exportWorkLoad(params);
        // console.log(res);
        if (res.status == 200) {
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: res.msg,
              type: "error",
            });
          } else if (res.status == 200) {
            let updateTime = this.addDate();
            let blob = new Blob([res.data], {
              type: "application/x-xls",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            //配置下载的文件名
            link.download = this.yearMonthtext+"-" + updateTime + ".xls";
            link.click();
            this.mouExpClick = true;
            this.mouExpClickLoadin = false;
          }
        }
      },
      //获取当前年月日
      addDate() {
        const nowDate = new Date();
        const date = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        const newmonth = date.month >= 10 ? date.month : "0" + date.month;
        const day = date.date >= 10 ? date.date : "0" + date.date;
        let updateTime = date.year + "-" + newmonth + "-" + day;
        return updateTime;
      },
        // 表格第一行上色
        tableRowClassName({ row, rowIndex }) {
            if (row.base == "0" && rowIndex == 0) {
                return "oneBack";
            }
        },
        confiChange(val) {
            // console.log(val);
            this.confivalue = val;
            this.getDepartmentPriceRatioStandList();
        },
        //设置表格翻页的序号递增
        indexMethod(index) {
            let equivalentpageNum = this.equivalentpageNum; // 当前页码
            let equivalentpageSize = this.equivalentpageSize; // 每页条数
            return index + 1 + (equivalentpageNum - 1) * equivalentpageSize; // 返回表格序号
        },
        // 项目总收入添加图标
        renderHeader(h, index) {
            let that = this;
            let text = "";
            if (index == "发生量") {
                text = "total";
            } else if (index == "基准项目比价系数") {
                text = "baseClass";
            } else if (index == "标化工作量") {
                text = "standWork";
            }
            return h("div", null, [
                h("span", null, [
                    h("span", index),
                    h(
                        "span",
                        {
                            class: "caret-wrapper " + text,
                            on: {
                                click: function () {
                                    if (index == "发生量") {
                                        that.orderby("total", 1);
                                    } else if (index == "基准项目比价系数") {
                                        that.orderby("baseClass", 1);
                                    } else if (index == "标化工作量") {
                                        that.orderby("standWork", 1);
                                    }
                                },
                            },
                        },
                        [
                            h("i", {
                                class: "sort-caret ascending",
                            }),
                            h("i", {
                                class: "sort-caret descending",
                            }),
                        ]
                    ),
                ]),
            ]);
        },
        async orderby(orderBy, a) {
            if (a === 1) {
                if (orderBy == "total") {
                    document.querySelector(
                        ".baseClass" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".baseClass" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                    document.querySelector(
                        ".standWork" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".standWork" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                } else if (orderBy == "baseClass") {
                    document.querySelector(
                        ".total" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".total" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                    document.querySelector(
                        ".standWork" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".standWork" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                } else if (orderBy == "standWork") {
                    document.querySelector(
                        ".total" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".total" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                    document.querySelector(
                        ".baseClass" + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        ".baseClass" + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                }
                // 项目总收入
                if (this.sortNum == 0) {
                    this.isAsc = "asc";
                    this.sortNum = 1;
                    document.querySelector(
                        "." + orderBy + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#66b1ff";
                } else if (this.sortNum == 1) {
                    this.isAsc = "desc";
                    this.sortNum = 2;
                    document.querySelector(
                        "." + orderBy + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        "." + orderBy + " .sort-caret.descending"
                    ).style.borderTopColor = "#66b1ff";
                } else if (this.sortNum == 2) {
                    document.querySelector(
                        "." + orderBy + " .sort-caret.ascending"
                    ).style.borderBottomColor = "#C0C4CC";
                    document.querySelector(
                        "." + orderBy + " .sort-caret.descending"
                    ).style.borderTopColor = "#C0C4CC";
                    this.isAsc = "";
                    this.sortNum = 0;
                }
                this.orderBy = orderBy;
                this.getDepartmentPriceRatioStandList();
            } else if (a === 2) {
                document.querySelector(
                    ".total" + " .sort-caret.ascending"
                ).style.borderBottomColor = "#C0C4CC";
                document.querySelector(
                    ".total" + " .sort-caret.descending"
                ).style.borderTopColor = "#C0C4CC";
                document.querySelector(
                    ".baseClass" + " .sort-caret.ascending"
                ).style.borderBottomColor = "#C0C4CC";
                document.querySelector(
                    ".baseClass" + " .sort-caret.descending"
                ).style.borderTopColor = "#C0C4CC";
                document.querySelector(
                    ".standWork" + " .sort-caret.ascending"
                ).style.borderBottomColor = "#C0C4CC";
                document.querySelector(
                    ".standWork" + " .sort-caret.descending"
                ).style.borderTopColor = "#C0C4CC";
                this.orderBy = "";
                this.isAsc = "";
            }
        },
        // 获取科室标化工作总量列表 分页
        // 查询科室年份列表 分页
        async getDepartmentYearList() {
            let data;
            if (this.hospital == "医院管理员") {
                data = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    departmentId: this.depart, //科室id
                };
            } else {
                data = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    departmentId: this.departmentId, //科室id
                };
            }
            let { data: res } = await this.$axios.getDepartmentYearList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室标化工作量列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getDepartmentYearList();
        },
        equivalentdialogVisibleClose() {
            this.workldvalue = "";
            this.getworkldModel.searchValue = "";
            this.equivalentdialogVisible = false;
            // this.orderby(1, 2);
            this.equivalentpageNum = 1;
            this.confivalue = "";
            this.basIdePvalue=''
        },
        // 旧的点击重新计算
        // async updateBaseClassByStandWork() {
        //   // 重新计算按钮点击后置灰2秒
        //   this.cesuanClick = false;
        //   this.cesuanClickKing = true;
        //   let data = _qs.stringify({
        //     departmentId: this.departmentId, //科室id
        //     dId: this.dId, //科室月份id
        //   });
        //   let { data: res } = await this.$axios.updateBaseClassByStandWork(data);
        //   // console.log(res);
        //   this.cesuanClick = true;
        //   this.cesuanClickKing = false;
        //   if (res.code == 401) {
        //     this.$router.push("/login");
        //   } else if (res.code == 500) {
        //     this.$message({
        //       message: "重新计算失败",
        //       type: "error",
        //     });
        //   } else if (res.code == 200) {
        //     this.getDepartmentPriceRatioStandList();
        //   }
        // },
        // 新的重新计算
        async updateBaseClassByStandWork() {
            // 重新计算按钮点击后置灰2秒
            this.cesuanClick = false;
            this.cesuanClickKing = true;
            let data = _qs.stringify({
                structuralAdjustmentId: this.confivalue, //结构调整策略id
                id: this.dId, //科室月份id
            });
            let { data: res } = await this.$axios.configureStrategy(data);
            // console.log(res);
            this.cesuanClick = true;
            this.cesuanClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.getDepartmentPriceRatioStandList();
                this.getDepartmentWorkLoad()
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 点击操作
        async WorkloadClickdialogVisible(row) {
            this.yid = row.id;
            this.yearValue = row.year;
            this.yeartext = row.year + "年科室标化工作量";
            let data = {
                yId: this.yid, //科室年份表id
            };
            let { data: res } = await this.$axios.getDepartmentYearDetailsList(
                data
            );
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "查询科室标化工作量列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.WorkloaddialogVisible = true;
                this.WorkloadtableData = res.rows;
                let baseTotalZ = 0;
                let basePriceZ = 0;
                let workValueZ = 0;
                let strategicPriceWorkingEquivalentValueZ = 0;
                let strategicPriceWorkloadValueZ = 0;
                let diffSumValueZ = 0;
                let commonValueZ = 0;
                let skillValueZ = 0;

                res.rows.forEach((item) => {
                    baseTotalZ += item.baseTotal;
                    basePriceZ += item.basePrice;
                    workValueZ += item.workValue;
                    diffSumValueZ += item.diffSum;
                    commonValueZ += item.commonValue;
                    skillValueZ += item.skillValue;
                    strategicPriceWorkloadValueZ += item.strategicPriceWorkload;
                    strategicPriceWorkingEquivalentValueZ +=
                        item.strategicPriceWorkingEquivalent;
                    return (
                        baseTotalZ,
                        basePriceZ,
                        workValueZ,
                        strategicPriceWorkloadValueZ,
                        strategicPriceWorkingEquivalentValueZ,
                        diffSumValueZ,
                        commonValueZ,
                        skillValueZ
                    );
                });
                this.ZongtableData[0].one = basePriceZ.toFixed(2);
                this.ZongtableData[0].two = baseTotalZ.toFixed(2);
                this.ZongtableData[0].three = workValueZ.toFixed(2);
                this.ZongtableData[0].six = diffSumValueZ.toFixed(2);
                this.ZongtableData[0].seven = commonValueZ.toFixed(2);
                this.ZongtableData[0].eight = skillValueZ.toFixed(2);
                this.ZongtableData[0].four =
                    strategicPriceWorkloadValueZ.toFixed(2);
                this.ZongtableData[0].five =
                    strategicPriceWorkingEquivalentValueZ.toFixed(2);

                this.ZongtableData[1].one = (basePriceZ / 12).toFixed(2);
                this.ZongtableData[1].two = (baseTotalZ / 12).toFixed(2);
                this.ZongtableData[1].three = (workValueZ / 12).toFixed(2);
                this.ZongtableData[1].six = (diffSumValueZ / 12).toFixed(2);
                this.ZongtableData[1].seven = (commonValueZ / 12).toFixed(2);
                this.ZongtableData[1].eight = (skillValueZ / 12).toFixed(2);
                this.ZongtableData[1].four = (
                    strategicPriceWorkloadValueZ / 12
                ).toFixed(2);
                this.ZongtableData[1].five = (
                    strategicPriceWorkingEquivalentValueZ / 12
                ).toFixed(2);
            }
        },
        // 点击工作量当量的操作
        async equivalentClickdialogVisible(row) {
            if (row.structuralAdjustmentId) {
                this.confivalue = row.structuralAdjustmentId;
            }
            // this.Scientific = row.baseTotal;
            this.dId = row.id;
            this.yId = row.yId;
            this.yearMonthtext =
                this.yearValue + "年" + row.month + "月科室标化工作量";
            this.getDepartmentPriceRatioStandList();
            // let data = {
            //     departmentId: row.departmentId, //科室id
            //     pageNum: "", //页数
            //     pageSize: "", //每页显示数量
            //     yearId: row.yId, //科室年份工作量id
            //     monthId: row.id, //科室月份工作量id
            // };
            // let { data: res } = await this.$axios.strList(data);
            // // console.log(res);
            // if (res.code == 401) {
            //     this.$router.push("/login");
            // } else if (res.code == 200) {
            //     this.confioptions = res.rows;
            // } else {
            //     this.$message({
            //         message: res.msg,
            //         type: "error",
            //     });
            // }
        },
        async getDepartmentPriceRatioStandList() {
            let data ={
                pageNum: this.equivalentpageNum, //页数
                pageSize: this.equivalentpageSize, //每页显示条数
                dId: this.dId, //科室月份数据id
                type: this.workldvalue, //项目类型（0 非收费项目 1 收费项目 ）不传此参数就是全部
                yId: this.yId, //科室年份id
                orderByColumn: this.orderBy, //排序参数（total 年度发生量 revenues 总收入 diff_class 难度系数 base 基准项目置顶）不需要排序的话可不传
                isAsc: this.isAsc, //asc 升序 desc降序
                searchValue: this.getworkldModel.searchValue, //搜索值
              prescribingDepartmentName:this.basIdePvalue  //基础科室
            };
            let { data: res } =
                await this.$axios.getDepartmentWorkLoadList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "查询科室标化工作量月份数据失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.equivalentdialogVisible = true;
                this.equivalenttableData = res.rows;
                this.equivalenttotal = res.total;
              this.basiDepChang()
              this.getDepartmentWorkLoad()
            }
        },
        // 2015年1月工作总量分页
        handleCurrentequivalentChange(val) {
            this.equivalentpageNum = val;
            this.getDepartmentPriceRatioStandList();
        },
        // 2015年1月工作总量失去焦点的时候
        getworkldDataList() {
            this.equivalentpageNum = 1;
            this.getDepartmentPriceRatioStandList();
            this.getDepartmentWorkLoad()
        },
        // 2015年1月工作总量选项框
        typeworkldDataListValueChange(workldvalue) {
            this.workldvalue = workldvalue;
            this.equivalentpageNum = 1;
            this.getDepartmentPriceRatioStandList();
        },
        // 导出科室年份下所有月数据
        async exportYearExcel(row) {
            this.cesuanExpClick = false;
            this.cesuanExpClickTwo = true;
            let data = {
                yId: row.id,
            };
            let res = await this.$axios.exportYearExcel(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "导出失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                let blob = new Blob([res.data], { type: "application/x-xls" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                //配置下载的文件名
                link.download =
                    this.departmentName +
                    row.year +
                    "年科室标化工作量" +
                    ".xls";
                link.click();
                this.cesuanExpClick = true;
                this.cesuanExpClickTwo = false;
            }
        },
        // 导出科室工作量单月数据
        async exportMonthExcel(row) {
            this.cesuanExpTwoClick = false;
            this.cesuanExpTwoClickTwo = true;
            let data = {
                yId: row.yId,
                dId: row.id,
            };
            let res = await this.$axios.exportMonthExcel(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "导出失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                let blob = new Blob([res.data], { type: "application/x-xls" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                //配置下载的文件名
                link.download =
                    this.departmentName +
                    this.yearValue +
                    "年" +
                    row.month +
                    "月" +
                    "科室标化工作量" +
                    ".xls";
                link.click();
                this.cesuanExpTwoClick = true;
                this.cesuanExpTwoClickTwo = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .oneBack {
    background: #c4dfec;
}

.FormType .el-select {
    width: 130px;
}
.downBut {
    width: 44px;
    height: 28px;
    position: relative;
    top: 4px;
    .el-icon-download {
        font-size: 16px;
        position: relative;
        top: -2px;
        left: -1px;
    }
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

.el-pagination {
    margin-top: 20px;
}

/deep/ .tanchu .el-dialog {
    margin: 0 auto;
}
</style>
